import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private spinnerVisible = new BehaviorSubject<boolean>(false);
  spinnerVisible$ = this.spinnerVisible.asObservable(); // Osservabile per lo stato dello spinner

  showSpinner(): void {
    this.spinnerVisible.next(true);
  }

  hideSpinner(): void {
    this.spinnerVisible.next(false);
  }
}
