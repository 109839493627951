import { Component, Input } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { UnitEntity } from 'src/app/models/units.models';
import { UserData } from 'src/app/models/users.models';
import { PlatformService } from 'src/app/services/platform.service';
import { UnitsService } from 'src/app/services/units.service';
import { UsersService } from 'src/app/services/users.service';
import { AppTheme, Group, UserDisplayMode } from 'src/app/types/users.types';

@Component({
    selector: 'unit-avatar',
    templateUrl: './component.html',
    styleUrls: ['./component.scss']
})
export class ItemUnitAvatarComponent {
    public unitName: string;
    public unitCode: string;
    public avatar: string;
    private unitId: string;
    public imageDataUrl: string;
    public className: string;
    public classButton: string;
    public avatarUnit: string;
    backgroundGrid: string;
    @Input() role: string;
    @Input() theme: AppTheme;
    @Input() userId: string;
    @Input()
    set unit(value: UnitEntity) {
        if (value) {
            const { unitName, unitCode, unitId, image } = value;

            this.unitName = unitName;
            this.unitCode = unitCode;
            this.unitId = unitId;
            if(!this.avatarUnit || (this.avatarUnit !== image) ) {
                this.avatarUnit = image
            //Scarichiamo l'immagine della unit
            this.unitsService.unitDownloadImage(unitId).subscribe((image) => {
                if (image) {
                    const blob: Blob = new Blob([new Uint8Array(<Buffer>image['data'])], { type: 'image/webp' });
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = () => (this.imageDataUrl = <string>reader.result);
                    // Impostiamo il backgroundGrid solo dopo che l'immagine è stata caricata
                    this.setBackgroundGrid();
                } else {
                    this.imageDataUrl = 'assets/backgrounds/unit-default.webp';
                    this.setBackgroundGrid();
                }
            });
            }
        }
    }
    @Input() userDisplayMode: UserDisplayMode;
    @Input() group: Group;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private unitsService: UnitsService, private navCtrl: NavController, private platformService: PlatformService, private usersService: UsersService) {
        this.setStyle();
        this.platformService.resize.subscribe(async () => this.setStyle());
    }

    ngOnInit() {
        this.setStyle();
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private setStyle = () => {
        if (this.platformService.isDesktop()) {
            this.className = 'h2-roboto-medium-22';
            this.classButton = 'caption-roboto-bold-13 medium-' + this.theme;
        }
        if (this.platformService.isSmartphone()) {
            (this.className = 'h2-roboto-medium-22'), (this.classButton = 'secondary-roboto-bold-14 medium-' + this.theme);
        }
        if (this.platformService.isTablet()) {
            (this.className = 'h2-roboto-medium-22'), (this.classButton = 'secondary-roboto-bold-14 medium-' + this.theme);
        }
    };
    private setBackgroundGrid(): void {
        //non toccare, chiedere Consu
        if (this.theme === AppTheme.dark) {
            this.backgroundGrid = `
                url(${this.imageDataUrl}) center center / cover no-repeat,
                filter: blur(6px);
            `;
        } else {
            this.backgroundGrid = `
            url(${this.imageDataUrl}) center center / cover no-repeat,
            filter: blur(6px);
        `;
            // this.backgroundGrid = `
            //     linear-gradient(to top, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0)),
            //     url(${this.imageDataUrl}) center center / cover no-repeat,
            //     filter: blur(6px);
            // `;
        }
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    unitEdit = (): void => {
        this.navCtrl.navigateRoot(`/indoor/unit/edit`, { replaceUrl: true });
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    unitIndoorExit = (): void => {
        let updateUserData: UserData = {lastHomeIn: null}
        this.usersService.editLoggedUser(this.userId, updateUserData).subscribe( () =>{
            this.navCtrl.navigateRoot(`/outdoor`, { replaceUrl: true });
        });
    };
}
