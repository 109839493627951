import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { Option } from 'src/app/components/shared/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { EntityView } from 'src/app/models/application.models';
import { UsersService } from 'src/app/services/users.service';
import { getUpdatedValues } from 'src/app/utilities/utilities';
import { AppTheme, DaysTimeWindow, Language, Role } from 'src/app/types/users.types';
import { InviteStatus, UserByUnitData, UserByUnitEntity, UserEntity, UserInviteData, UserInviteResult } from 'src/app/models/users.models';
import { ViewEntity } from 'src/app/models/views.models';
import { HttpErrorResponse } from '@angular/common/http';
import { PlatformService } from 'src/app/services/platform.service';
import { ViewsService } from 'src/app/services/views.service';
import { TimeWindowService } from 'src/app/services/time-window.service';

@Component({
    selector: 'admitted-user-edit-modal',
    templateUrl: './admitted-user-edit-modal.component.html',
    styleUrls: ['./admitted-user-edit-modal.component.scss']
})
export class AdmittedUserEditModalComponent implements OnInit, OnDestroy {
    @Input() theme: AppTheme;
    @Input() user: UserByUnitEntity = {} as UserByUnitEntity;
    public disableUser: boolean = true;
    public titleWindowsEnable = 'user.timeWindowsEnable';
    public daysTimeWindows: Array<DaysTimeWindow> = [];
    public timeWindowsEnable: boolean;
    public checkIn: string;
    public checkOut: string;
    public contentClass: string;
    public roleSelected: Role;
    public languageSelected: Language;
    public subs: Subscription;

    public indoorUser: UserByUnitEntity;
    public views: ViewEntity[];
    public view: ViewEntity = {} as ViewEntity;
    public viewsFilteredByRole: ViewEntity[] = [];
    public viewName: string;
    public form: FormGroup;
    public firstName: string;
    public email: string;
    public image: string;
    public enable: boolean;
    public userEnable: boolean;
    public get Role(): typeof Role {
        return Role;
    }
    public isEnableChanged = false;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(
        private translate: TranslateService,
        public toastController: ToastController,
        private usersService: UsersService,
        public modalController: ModalController,
        private platformService: PlatformService,
        private viewsService: ViewsService,
        private timeWindowService: TimeWindowService
    ) {
        this.contentClass = 'ion-padding';
        this.subs = new Subscription();

        //Gestiamo i dati dell'utente indoor
        this.subs.add(
            this.usersService.indoorUserObserver.subscribe((user) => {
                this.indoorUser = user;
                this.theme = this.indoorUser.theme;
                //Scarichiamo l'immagine dell'utente

                //Appena riceviamo l'utente indoor, possiamo richiederne la lista degli utenti
                this.usersService.loadIndoorUsers(this.indoorUser.unitId).subscribe();
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async ngOnInit() {
        this.usersService.userDownloadImage(this.user.userId).subscribe((image) => {
            if (image) {
                const blob: Blob = new Blob([new Uint8Array(<Buffer>image['data'])], { type: 'image/webp' });
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = () => (this.image = <string>reader.result);
            } else {
                this.image = 'assets/backgrounds/user-default.webp';
            }
        });
        this.userEnable = this.timeWindowService.userEnable(this.user);
        this.roleSelected = this.user.role;
        const views = await firstValueFrom(this.viewsService.loadViewsList(this.user.unitId));
        this.daysTimeWindows = this.user.daysTimeWindows;
        const viewN = views.find((f) => f.viewId === this.user.viewId);
        this.viewName = viewN ? viewN.name : '';
        this.form = new FormGroup({
            role: new FormControl(this.user.role, { validators: Validators.required, updateOn: 'submit' }),
            viewId: new FormControl(this.viewName !== '' ? this.user.viewId : null, { validators: Validators.required, updateOn: 'submit' }),
            enable: new FormControl(this.userEnable, { validators: Validators.required, updateOn: 'submit' })
        });

        this.checkIn = this.user?.checkIn;
        this.checkOut = this.user?.checkOut;
        if (this.user.role === 'member') {
            this.timeWindowsEnable = this.user.daysTimeWindows?.length > 0 ?? true;
        } else if (this.user.role === 'guest') {
            if (this.user.checkIn !== null || this.user.checkIn !== undefined) {
                this.timeWindowsEnable = true;
            } else {
                this.timeWindowsEnable = false;
            }
        }
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    modalClose(): void {
        this.modalController.dismiss();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    editUser(): void {
        const { role, enable, viewId } = this.form.value;
        if (enable !== this.userEnable) {
            this.isEnableChanged = true;
        }

        const data = {
            unitId: this.user.unitId,
            firstName: this.user.firstName,
            inviterEmail: this.indoorUser.email,
            inviterName: this.indoorUser.firstName,
            email: this.user.email,
            role,
            enable,
            viewId,
            daysTimeWindows: this.daysTimeWindows,
            checkIn: this.checkIn,
            checkOut: this.checkOut,
            timeWindowsEnable: this.timeWindowsEnable
        };
        console.debug('API:', data);

        this.usersService.editIndoorAdmittedUser(this.user.unitId, this.user.userId, data, this.isEnableChanged).subscribe({
            next: (result: UserByUnitEntity) => {
                //Ritorniamo il result per aggiornare la lista
                this.modalController.dismiss(result);
            },
            error: (error: HttpErrorResponse) => {
                alert(error.message);
                this.modalController.dismiss();
            }
        });
    }
    enableUser(): boolean {
        if (this.indoorUser.role === Role.member) {
            if (this.user.role === Role.member || this.user.role === Role.guest) {
                return false;
            } else {
                return true;
            }
        } else if (this.indoorUser.role === Role.owner || this.indoorUser.role === Role.service) {
            return false;
        }
        return false;
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    userUnlink(): void {
        this.usersService.unlinkUser(this.user.userId, this.user.unitId).subscribe({
            next: () => {
                alert('Utente scollegato');
                this.modalController.dismiss();
            },
            error: (error: HttpErrorResponse) => {
                alert(error.message);
                this.modalController.dismiss();
            }
        });
    }
    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async toggleEnable(event: boolean) {
        this.form.controls.enable.setValue(event);
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async changeLanguage(event: any, key: string) {
        this.languageSelected = event.value;
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async changeRole(event: any, key: string) {
        if (event.value !== this.roleSelected) {
            this.roleSelected = event.value;
            this.form.controls.role.setValue(event.value);
            this.form.controls.viewId.setValue(null);
        }
        if (event.value === 'owner' || event.value === 'service') {
            this.daysTimeWindows = null;
            this.timeWindowsEnable = false;
            this.checkIn = null;
            this.checkOut = null;
        }
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async changeView(event: any, key: string) {
        this.form.controls.viewId.setValue(event.viewId);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    timeWindowsEnableValueChanged(newValue: boolean) {
        this.timeWindowsEnable = newValue;

        this.checkIn = null;
        this.checkOut = null;
    }
    changedTime(data: any) {
        // Funzione per rimuovere l'offset di fuso orario da una stringa di data ISO
        const removeTimezoneOffset = (isoString: string) => isoString.replace(/(\+|\-)\d\d:\d\d$/, '');

        // Controlla e modifica checkIn e checkOut se presenti
        this.checkIn = data.checkIn ? removeTimezoneOffset(data.checkIn) : data.checkIn;
        this.checkOut = data.checkOut ? removeTimezoneOffset(data.checkOut) : data.checkOut;

        this.timeWindowsEnable = true;
        this.daysTimeWindows = null;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    daysValueChanged(data: DaysTimeWindow[]) {
        this.daysTimeWindows = data;
        this.checkIn = null;
        this.checkOut = null;
    }
    ngOnDestroy(): void {
        this.daysTimeWindows = this.checkIn = this.checkOut = this.timeWindowsEnable = null;
    }
}
