import { UserEntity } from 'src/app/models/users.models';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map, skipWhile } from 'rxjs';
import { Group, UserDisplayMode } from '../types/users.types';
import { UsersService } from '../services/users.service';
import { HomeService } from '../services/home.service';
import { AnomaliesService } from '../services/anomalies.service';

@Injectable({ providedIn: 'root' })
export class OutdoorGuard implements CanActivate {
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(
        private router: Router,
        private authService: AuthService,
        private homeService: HomeService,
        private usersService: UsersService,
        private anonaliesUnitService: AnomaliesService
    ) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //Carichiamo lo userId salvato in sessione
        const userId = this.authService.getUserIdFromStorage();
        //Notifichiamo di essere usciti di casa
        this.homeService.emitHomeOutState();

        //Carichiamo le anomalie outdoor
        this.anonaliesUnitService.loadAnomaliesByUserList(userId).subscribe();

        return this.usersService.loadLoggedUserEntity(userId).pipe(
            map((user: UserEntity) => {
                //*********************
                //TODO Verificare che l'utente non sia disabilitato (o che esista ancora)
                //*********************
                //Se ci viene richiesta la rotta "outdoor" (cioè appena dopo il login)...
                if (state.url == '/outdoor') {
                    //Facciamo un redirect in base al gruppo dell'utente loggato
                    switch (user.group) {
                        case Group.administrator:
                            console.debug('Yes! Redirecting to: /outdoor/dashboard');
                            return this.router.parseUrl('/outdoor/dashboard');
                        case Group.support:
                            console.debug('Yes! Redirecting to: /outdoor/dashboard');
                            return this.router.parseUrl('/outdoor/dashboard');
                        case Group.customer:
                            if(user.lastHomeIn) {
                                this.homeService.homeIn(user.lastHomeIn, userId);
                            } else {
                                console.debug('Yes! Redirecting to: /outdoor/units/list');
                                //Facciamo un redirect in base alla visualizzazione preferita dell'utente loggato
                                switch (user.displayMode) {
                                    case UserDisplayMode.card:
                                        return this.router.parseUrl('/outdoor/units/list/card');
                                    case UserDisplayMode.map:
                                        return this.router.parseUrl('/outdoor/units/list/map');
                                    case UserDisplayMode.row:
                                        return this.router.parseUrl('/outdoor/units/list/row');
                                    // Di default, se non è impostata la visualizzazione preferita, usiamo quella a "card"
                                    default:
                                        return this.router.parseUrl('/outdoor/units/list/card');
                                }
                            }
                        default:
                            return this.router.parseUrl('/welcome/login');
                    }
                } else {
                    console.debug('Yes!');
                    return true;
                }
            })
        );
    }

    // /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
}
