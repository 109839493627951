<ng-container *ngIf="enable">
    <ion-list class="ion-no-padding">
        <ion-menu-toggle auto-hide="false">
            <!-- Avatar dell'utente loggato -->
            <user-avatar [user]="loggedUserEntity" (userEdit$)="userEdit()"></user-avatar>
            <!-- Card della unit indoor -->
            <unit-avatar *ngIf="indoor" [theme]="theme" [unit]="indoorUnitEntity" [role]="indoorUserEntity.role" [group]="group" [userId]="loggedUserEntity.userId"></unit-avatar>
        </ion-menu-toggle>
    </ion-list>
    <!-- Lista delle voci di menu -->
    <ion-list class="ion-no-padding">
        <ng-container *ngFor="let item of menuItems; trackBy: trackItems">
            <ion-menu-toggle menu="pages" auto-hide="false">
                <ion-item button detail="false" lines="none" routerDirection="root" [routerLink]="[item.url]" [color]="item.color">
                    <ion-icon
                        style="padding-right: 0; margin-right: 15px"
                        size="small"
                        slot="start"
                        [name]="item.icon"
                        [ngStyle]="{ color: item.textColor }"
                    ></ion-icon>
                    <ion-text class="text-input-roboto-regular-16" [ngStyle]="{ color: item.textColor }">{{ item.title | translate }}</ion-text>
                </ion-item>
            </ion-menu-toggle>
        </ng-container>
    </ion-list>
    <!-- {{ this.indoorUserEntity?.role }} -->
</ng-container>
