import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class DialogsService {
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private loadingController: LoadingController, private translate: TranslateService) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public async openLoadingDialog(): Promise<HTMLIonLoadingElement> {
        const message = '<ion-img src="/assets/svg/spinner.svg" alt="loading..."></ion-img>';
        const loading = await this.loadingController.create({
            cssClass: 'lili-loading',
            message,
            spinner: null,
            duration: 2000000
        });
        await loading.present();
        return loading;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public async openLoadingRefreshDialog(): Promise<HTMLIonLoadingElement> {
        const message = `
        <ion-img src="/assets/svg/spinner-refresh.svg" alt="loading..."></ion-img>
        <br>
        <div>
            ${this.translate.instant('message.spinner.updating')}
        </div>`;
        const loading = await this.loadingController.create({
            cssClass: 'lili-loading',
            message,
            spinner: null
        });
        await loading.present();
        return loading;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public async openOfflineDialog(): Promise<HTMLIonLoadingElement> {
        //TODO Commento in attesa di trovare l'icona giusta
        //const message = `<ion-img src="/assets/ha/svg/1000.svg" alt="offline..."></ion-img><p class="ion-text-center"><b>OFFLINE</b></p>`;
        const message = `<p class="ion-text-center"><b>Connessione in corso...</b></p>`;
        const loading = await this.loadingController.create({
            cssClass: 'lili-loading',
            message,
            spinner: null,
            duration: 2000000
        });
        await loading.present();
        return loading;
    }
}
