<ion-grid class="ion-no-margin ion-no-padding">
    <ion-row class="ion-no-margin ion-no-padding">
        <ion-col size="6" *ngFor="let daysTimeWindow of daysTimeWindows; index as i">
            <!-- Card finestra temporale member -->
            <ion-card class="card-time-window">
                <ion-grid class="ion-no-margin ion-no-padding">
                    <ion-row class="ion-justify-content-between ion-align-items-center">
                        <ion-col size="3" class="ion-text-start">
                            <svg-icon [src]="'assets/ha/svg/watch.svg'" [svgStyle]="{ 'height.px': 25, fill: '#c5c5c5' }"></svg-icon>
                        </ion-col>
                        <ion-col size="3" class="ion-text-end ion-no-margin ion-no-padding">
                            <ion-icon
                                type="button"
                                size="small"
                                [color]="theme"
                                class="pointer"
                                name="ellipsis-vertical"
                                (click)="presentPopover($event, i)"
                            ></ion-icon>
                        </ion-col>
                    </ion-row>
                    <ion-row class="ion-no-margin ion-no-padding">
                        <ion-col size="12">
                            <ion-label class="pointer" style="margin: 16px 0">
                                <ng-container *ngFor="let isDaySelected of daysTimeWindow.days; index as index">
                                    <ion-text [class]="classDays" *ngIf="isDaySelected">{{ days[index].key | translate }},</ion-text>
                                </ng-container>
                                <br />
                                <ion-text [class]="classDays" style="color: #f15a24" *ngIf="daysTimeWindow.allday">
                                    {{ 'user.daysTimeWindows.allDay' | translate }}
                                </ion-text>
                            </ion-label>
                            <ion-label *ngIf="!daysTimeWindow.allday">
                                <ion-text *ngIf="fromTrigger" [class]="classDays" style="color: #f15a24">{{ daysTimeWindow.start }}</ion-text>
                                <ion-text *ngIf="!fromTrigger" [class]="classDays" style="color: #f15a24">{{ daysTimeWindow.start }} - {{ daysTimeWindow.end }}</ion-text>
                            </ion-label>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-card>
        </ion-col>
    </ion-row>
</ion-grid>
