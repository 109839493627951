<form [formGroup]="userEditForm" (ngSubmit)="submitRequest()">
    <ion-grid fixed style="border-bottom: 2 px solid white">
        <ion-row style="margin-left: 8px; margin-right: 8px">
            <ion-col size="12">
                <h6>{{ 'days.labels.select-days' | translate }}</h6>
            </ion-col>
        </ion-row>
        <ion-row style="margin-left: 8px; margin-right: 8px">
            <ion-col size="auto" *ngFor="let day of days; let index = index">
                <ion-button mode="ios" class="buttonDays" (click)="toggleDaySelection(day.value)" size="default" [ngClass]="getCombinedClass(index)">
                    <ion-text>{{ day.key | translate | uppercase }}</ion-text>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
    <hr />
    <ion-list style="margin-left: 8px; margin-right: 8px">
        <ion-grid>
            <ion-row>
                <ion-col size="12">
                    <item-input-toggle
                        [theme]="theme"
                        inputType="toggle"
                        [title]="'Tutto il giorno'"
                        label="days.labels.allday"
                        labelPosition="undefined"
                        formControlName="allday"
                        lines="true"
                        [checked]="userEditForm.controls.allday.value"
                        (itemValueChange$)="toggleFullDay($event)"
                    ></item-input-toggle>
                </ion-col>
            </ion-row>
            <ng-container *ngIf="!fullDay">
                <ion-row style="border-bottom: 1px solid rgba(189, 189, 189, 0.404); margin-left: 8px; margin-right: 8px">
                    <ion-col size="12">
                        <ion-item detail="false" lines="none">
                            <ion-text slot="start">Inizio</ion-text>
                            <div class="time-wrap ion-text-center ion-justify-content-center ion-align-items-center" slot="end" (click)="step(0)">
                                <ion-text type="button" [ngClass]="{ selectDay: isSelected(0), unselectDay: !isSelected(0) }">
                                    {{ userEditForm.value.start }}
                                </ion-text>
                            </div>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="!fromTrigger" style="border-bottom: 1px solid rgba(189, 189, 189, 0.404); margin-left: 8px; margin-right: 8px">
                    <ion-col size="12">
                        <ion-item detail="false" lines="none">
                            <ion-text slot="start">Fine</ion-text>
                            <div class="time-wrap ion-text-center ion-justify-content-center ion-align-items-center" slot="end" (click)="step(1)">
                                <ion-text type="button" [ngClass]="{ selectDay: isSelected(1), unselectDay: !isSelected(1) }">
                                    {{ userEditForm.value.end }}
                                </ion-text>
                            </div>
                        </ion-item>
                    </ion-col>
                </ion-row>

                <ion-row [ngSwitch]="stepIndex">
                    <!-- Start Time -->
                    <ion-col size="12" *ngSwitchCase="0">
                        <ion-datetime [color]="theme" size="fixed" presentation="time" hourCycle="h24" formControlName="start"></ion-datetime>
                    </ion-col>
                    <!-- End Time -->
                    <ion-col size="12" *ngSwitchCase="1">
                        <ion-datetime *ngIf="!fromTrigger" [color]="theme" size="fixed" presentation="time" hourCycle="h24" formControlName="end"></ion-datetime>
                    </ion-col>
                </ion-row>
            </ng-container>
        </ion-grid>
    </ion-list>
    <ion-button type="submit" expand="block" size="default" [color]="theme" style="margin-left: 16px; margin-right: 16px" [disabled]="!isFormValid">
        {{ 'button.confirm' | translate }}
    </ion-button>
</form>
