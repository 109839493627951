<ion-item
    button
    [formGroup]="form"
    (click)="openOptions()"
    detail="true"
    lines="full"
    mode="ios"
    class="ion-no-margin ion-no-padding"
>
    <ion-label class="ion-no-margin ion-no-padding">
        <ion-text [ngClass]="'medium-'+theme" class="label-item-roboto-regular-13 ion-no-margin ion-no-padding">{{ label | translate }}</ion-text>
        <ion-input *ngIf="showInputName" [type]="inputType" [readonly]="readonly" [value]="option?.name"></ion-input>
        <ion-input [hidden]="showInputName" [type]="inputType" [formControlName]="controlName" [readonly]="readonly" [placeholder]="placeholder"></ion-input>
        <ng-container *ngIf="option">
            <ion-thumbnail *ngIf="option.avatar && theme" slot="end" class="avatar">
                <svg-icon [src]="'assets/ha/svg/' + option.avatar + '.svg?'" [svgStyle]="{ 'height.px':25,'width.px':25,'fill':'#c5c5c5'}"></svg-icon>
            </ion-thumbnail>
            <!-- <ion-thumbnail *ngIf="option.thumbnail" slot="end">
                <ion-img [src]="option.thumbnail"></ion-img>
            </ion-thumbnail> -->
    
            <ion-icon class="icon" *ngIf="option.icon && !option.avatar" slot="end" [name]="option.icon" [color]="theme" size="large"></ion-icon>
        </ng-container>
    </ion-label>
</ion-item>
