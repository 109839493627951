import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Observable, ReplaySubject, Subscription, map, mapTo, of, tap } from 'rxjs';
import { UserByUnitData, UserByUnitEntity, UserData, UserEntity, UserPendingEntity, UserInviteResult, UsertMaintenanceAuthEntity } from '../models/users.models';
import { Group, Language } from '../types/users.types';
import { MaintainerByUserEntity } from '../interfaces/users.interfaces';
import { SocketioService } from './socketio.service';
import { environment } from 'src/environments/environment';
import { SubscriptionKind } from '../types/socketio.types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WebsocketMessage } from '../models/websocket.models';
import { WebsocketEventName } from '../models/events-names.models';

@Injectable({ providedIn: 'root' })
export class UsersService {
    private websocketSubscription: Subscription;

    //Utente loggato
    public loggedUserObserver: ReplaySubject<UserEntity>;
    private loggedUserEntity: UserEntity;

    // Utente editato
    public editedUserObserver: ReplaySubject<UserEntity>;
    private editedUserEntity: UserEntity;

    //Utenti listati outdoor
    public listedUsersObserver: ReplaySubject<UserEntity[]>;
    private listedUsersEntities: UserEntity[];

    //Conteggio utenti
    public countUsersObserver: ReplaySubject<number>;
    private countUsers: number;

    //Utente indoor
    public indoorUserObserver: ReplaySubject<UserByUnitEntity>;
    private indoorUserEntity: UserByUnitEntity;

    //Utenti listati indoor
    public indoorUsersObserver: ReplaySubject<UserByUnitEntity[]>;
    private indoorUsersEntities: UserByUnitEntity[];

    //Utenti pendenti
    public pendingUsersObserver: ReplaySubject<UserPendingEntity[]>;
    private pendingUsersEntities: UserPendingEntity[];

    //Boolean per autorizzazione manutenzione
    public allowMaintenanceObserver: ReplaySubject<boolean>;
    private allowMaintenance: boolean;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private backendService: BackendService, private socketioService: SocketioService, private http: HttpClient) {
        this.loggedUserObserver = new ReplaySubject<UserEntity>(1);
        this.listedUsersObserver = new ReplaySubject<UserEntity[]>(1);
        this.editedUserObserver = new ReplaySubject<UserEntity>(1);
        this.socketioService.getMessage(WebsocketEventName.updateUsers).subscribe((message: WebsocketMessage<UserEntity>) => {
            if (message) {
                this.loggedUserEntity.avatar = message.payload.avatar;
                this.loggedUserObserver.next(this.loggedUserEntity);
            }
        });
        this.countUsersObserver = new ReplaySubject<number>(1);
        this.indoorUserObserver = new ReplaySubject<UserByUnitEntity>(1);
        this.indoorUsersObserver = new ReplaySubject<UserByUnitEntity[]>(1);
        this.pendingUsersObserver = new ReplaySubject<UserPendingEntity[]>(1);
        this.allowMaintenanceObserver = new ReplaySubject<boolean>(1);

        //Ci mettiamo in ascolto dei messaggi websocket
        this.socketioService.getMessage(WebsocketEventName.allowMaintenance).subscribe((message: WebsocketMessage<boolean>) => {
            if (message) {
                this.allowMaintenance = true;
                this.allowMaintenanceObserver.next(this.allowMaintenance);
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public clearLoggedUserEntity() {
        //Se eravamo loggati
        if (this.loggedUserEntity) {
            //Ci disiscriviamo dal suo topic
            this.socketioService.topicUnsubscribe(this.loggedUserEntity.userId);
        }
        this.loggedUserEntity = null;
        this.loggedUserObserver.next(this.loggedUserEntity);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public clearIndoorUserEntity() {
        this.indoorUserEntity = {} as UserByUnitEntity;
        this.indoorUserObserver.next(this.indoorUserEntity);
    }


    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Cerchiamo degli utenti outdoor
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public usersSearch(match: string, limit = 10): Observable<UserEntity[]> {
        return this.backendService.get<UserEntity[]>(`/v2/users/search`, { match, limit }).pipe(
            map((users: UserEntity[]) => {
                //Aggiorniamo le info della lista utenti
                this.listedUsersEntities = users;
                //Notifichiamo i nuovi dati utente
                // console.log('Emitting new listedUsersEntities...', this.listedUsersEntities);
                this.listedUsersObserver.next(this.listedUsersEntities);
                return users;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Lista gli utenti outdoor
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public usersList(fetchSize: number, pageState?: string): Observable<UserEntity[]> {
        return this.backendService.get<UserEntity[]>(`/v2/users`, { fetchSize, pageState }).pipe(
            map((users: UserEntity[] | any) => {
                if (!pageState) {
                    //Aggiorniamo le info della lista utenti
                    this.listedUsersEntities = users.result;
                    //Notifichiamo i nuovi dati evento
                    console.log('Emitting virgin listedUsersEntities list...', this.listedUsersEntities);
                    this.listedUsersObserver.next(this.listedUsersEntities);
                } else {
                    for (let i = 0; i < users.result.length; i++) {
                        this.listedUsersEntities.push(users.result[i]);
                    }
                    //Notifichiamo i nuovi dati evento
                    console.log('Emitting added listedUsersEntities list...', this.listedUsersEntities);
                    this.listedUsersObserver.next(this.listedUsersEntities);
                }
                return users;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Lista gli utenti outdoor by group
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public usersListByGroup(group: Group, fetchSize: number, pageState?: string): Observable<UserEntity[]> {
        return this.backendService.get<UserEntity[]>(`/v2/users/by-group`, { group, fetchSize, pageState }).pipe(
            map((users: UserEntity[] | any) => {
                if (!pageState) {
                    //Aggiorniamo le info della lista utenti
                    this.listedUsersEntities = users.result;
                    //Notifichiamo i nuovi dati evento
                    console.log('Emitting virgin listedUsersEntities list...', this.listedUsersEntities);
                    this.listedUsersObserver.next(this.listedUsersEntities);
                } else {
                    for (let i = 0; i < users.result.length; i++) {
                        this.listedUsersEntities.push(users.result[i]);
                    }
                    //Notifichiamo i nuovi dati evento
                    console.log('Emitting added listedUsersEntities list...', this.listedUsersEntities);
                    this.listedUsersObserver.next(this.listedUsersEntities);
                }
                return users;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Leggiamo i dati dell'utente loggato
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public loadLoggedUserEntity(userId: string): Observable<UserEntity> {
        //Leggiamo i dati dell'utente loggato
        return this.backendService.get<UserEntity>(`/v2/users/read`, { userId }).pipe(
            map((user: UserEntity) => {
                //Aggiorniamo le info dell'utente loggato
                this.loggedUserEntity = user;
                //Ci iscriviamo al suo topic per avere gli aggiornamenti in tempo reale
                this.socketioService.topicSubscribe(user.userId, SubscriptionKind.user, 'Subscribing user');
                //Notifichiamo i nuovi dati utente
                // console.log('Emitting new loggedUserEntity...', this.loggedUserEntity);
                this.loggedUserObserver.next(this.loggedUserEntity);
                return user;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Aggiorniamo i dati dell'utente loggato (outdoor)
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public editLoggedUser(userId: string, data: UserData): Observable<UserEntity> {
        return this.backendService.put(`/v2/users/update`, { userId, ...data }).pipe(
            map((user: UserEntity) => {
                //Aggiorniamo le info dell'utente loggato
                this.loggedUserEntity = user;
                //Notifichiamo i nuovi dati utente
                // console.log('Emitting new loggedUserEntity...', this.loggedUserEntity);
                this.loggedUserObserver.next(this.loggedUserEntity);
                return user;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public usersCount(): Observable<number> {
        return this.backendService.get(`/v2/users/count`, {}).pipe(
            map((count: number) => {
                //Aggiorniamo il conteggio degli utenti
                this.countUsers = count;
                //Notifichiamo i nuovi dati utente
                this.countUsersObserver.next(this.countUsers);
                return count;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public usersFakeFill(quantity: number): Observable<number> {
        return this.backendService.post(`/v2/users/fake/fill`, {quantity}).pipe(
            map((count: number) => {
                //Ritorniamo il numero degli users creati
                return count;
            })
        );
    }

   /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public usersFakePurge(): Observable<number> {
        return this.backendService.delete(`/v2/users/fake/cleanup`, {}).pipe(
            map((count: number) => {
                //Ritorniamo il numero degli users cancellati
                return count;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Leggiamo un utente outdoor
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userRead(userId: string): Observable<UserEntity> {
        return this.backendService.get<UserEntity>(`/v2/users/read`, { userId }).pipe(
            map((user: UserEntity) => {
                //Aggiorniamo le info dell'utente editato
                this.editedUserEntity = user;
                //Notifichiamo i nuovi dati utente
                // console.log('Emitting new editedUserEntity...', this.editedUserEntity);
                this.editedUserObserver.next(this.editedUserEntity);
                return user;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Aggiorniamo i dati di un utente outdoor
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userUpdate(userId: string, data: UserData): Observable<UserData> {
        return this.backendService.put<UserData>(`/v2/users/update`, { userId, ...data });
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Aggiorniamo il group dell utente
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userUpdateGroup(userId: string, newGroup: Group): Observable<UserData> {
        return this.backendService.put<UserData>(`/v2/users/group/update`, { userId, newGroup });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userUploadImage(formData: FormData): Observable<void> {
        return this.backendService.post<void>(`/v2/users/image/upload`, formData, {
            options: { headers: {} }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userDownloadImage(userId: string): Observable<Buffer> {
        return this.backendService.get<Buffer>(`/v2/users/image/download`, { userId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userDeleteImage(userId: string): Observable<Buffer> {
        return this.backendService.delete<Buffer>(`/v2/users/image/delete`, { userId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Cancelliamo un utente outdoor
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userDelete(userId: string): Observable<void> {
        return this.backendService.delete<void>(`/v2/users/delete`, { userId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Otteniamo la lista degli utenti indoor
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public loadIndoorUsers(unitId: string): Observable<UserByUnitEntity[]> {
        //Leggiamo i dati della centrale in cui siamo
        return this.backendService.get<UserByUnitEntity[]>(`/v2/users/indoor`, { unitId }).pipe(
            map((users: UserByUnitEntity[]) => {
                //Aggiorniamo le info dell'utente indoor
                this.indoorUsersEntities = users;
                //Notifichiamo i nuovi dati utente
                // console.log('Emitting new listedIndoorUsersEntities...', this.indoorUsersEntities);
                this.indoorUsersObserver.next(this.indoorUsersEntities);
                return users;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Leggiamo i dati dell'utente indoor
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public readIndoorUser(userId: string, unitId: string): Observable<UserByUnitEntity> {
        //Leggiamo i dati della centrale in cui siamo
        return this.backendService.get<UserByUnitEntity>(`/v2/users/indoor/read`, { userId, unitId }).pipe(
            map((user: UserByUnitEntity) => {
                //Aggiorniamo le info dell'utente indoor
                this.indoorUserEntity = user;
                //Notifichiamo i nuovi dati utente
                // console.log('Emitting new indoorUserEntity...', this.indoorUserEntity);
                this.indoorUserObserver.next(this.indoorUserEntity);
                return user;
            })
        );
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Controlliamo se una vista ha utenti associati
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public checkUsersByView(unitId: string, viewId: string): Observable<void> {
        return this.backendService.get(`/v2/users/by-view`, { unitId, viewId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Leggiamo i dati dell'utente indoor solo per controllare il permesso di soggiorno
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public readPermessoDiSoggiorno(userId: string, unitId: string): Observable<UserByUnitEntity> {
        return this.backendService.get<UserByUnitEntity>(`/v2/users/indoor/read`, { userId, unitId }).pipe(
            tap((user: UserByUnitEntity) => {
                // handle if you want....
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Aggiorniamo i dati dell'utente indoor
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public editIndoorUser(unitId: string, userId: string, data: UserByUnitData): Observable<UserByUnitEntity> {
        return this.backendService.put(`/v2/users/indoor/update`, { unitId, userId, ...data }).pipe(
            map((user: UserByUnitEntity) => {
                //Aggiorniamo le info dell'utente loggato
                this.indoorUserEntity = user;
                //Notifichiamo i nuovi dati utente
                // console.log('Emitting new indoorUserEntity...', this.indoorUserEntity);
                this.indoorUserObserver.next(this.indoorUserEntity);
                return user;
            })
        );
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Aggiorniamo i dati dell'utente indoor ammesso
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public editIndoorAdmittedUser(unitId: string, userId: string, data: UserByUnitData, isEnableChanged: boolean): Observable<UserByUnitEntity> {
        return this.backendService.put(`/v2/users/indoor/update`, { unitId, userId, isEnableChanged, ...data });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Scolleghiamo un utente dalla centrale
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unlinkUser(userId: string, unitId: string): Observable<void> {
        return this.backendService.delete<void>(`/v2/users/indoor/unlink`, { userId, unitId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Otteniamo la lista degli utenti pendenti (invitati in un impianto ma ancora non registrati)
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public loadPendingUsers(unitId: string): Observable<UserPendingEntity[]> {
        //Leggiamo i dati della centrale in cui siamo
        return this.backendService.get<UserPendingEntity[]>(`/v2/users/indoor/pending`, { unitId }).pipe(
            map((users: UserPendingEntity[]) => {
                //Aggiorniamo la lista degli utenti pending
                this.pendingUsersEntities = users;
                //Notifichiamo i nuovi dati utenti pending
                // console.log('Emitting new pendingUsersEntities...', this.pendingUsersEntities);
                this.pendingUsersObserver.next(this.pendingUsersEntities);
                return users;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public findUsersByView(unitId: string, viewId: string): Observable<UserByUnitEntity[]> {
        //cerchiamo gli utenti per quella vista
        return this.backendService.get<UserByUnitEntity[]>(`/v2/users/by-view`, { unitId, viewId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Assengiamo una vista ad un utente indoor
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public assignView(userId: string, unitId: string, viewId: string): Observable<UserByUnitEntity> {
        return this.backendService.post<UserByUnitEntity>(`/v2/users/assign-view`, { userId, unitId, viewId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public inviteUser(unitId: string, inviterName: string, userData: UserByUnitData): Observable<UserInviteResult> {
        return this.backendService.post<UserInviteResult>(`/v2/users/indoor/invite`, {
            unitId,
            inviterName,
            redirect: environment.frontendUrl + '/welcome/activate',
            ...userData
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public reinviteUser(apikey: string, inviterName: string, data: UserByUnitData): Observable<void> {
        return this.backendService.post<void>(`/v2/users/indoor/pending/reinvite`, { apikey, inviterName, ...data });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public updatePendingUser(apikey: string, inviterName: string, data: UserByUnitData): Observable<void> {
        return this.backendService.post<void>(`/v2/users/indoor/pending/update`, { apikey, inviterName, ...data });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public revokeUserPending(apikey: string): Observable<void> {
        return this.backendService.delete<void>(`/v2/users/indoor/pending/revoke`, { apikey });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Invio mail di autorizzazione all utente
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public maintenanceAllowMail(userId: string, email: string, firstName: string, sender: string, redirect: string, language: Language, loggedUserId: string, loggedUserEmail: string): Observable<void> {
        return this.backendService.post<void>(`/v2/users/maintenance`, { 
            userId, 
            email, 
            firstName, 
            sender, 
            redirect, 
            language,
            loggedUserId,
            loggedUserEmail
        }).pipe(
            tap(() => {
                // Ci iscriviamo al suo topic per avere gli aggiornamenti in tempo reale
                this.socketioService.topicSubscribe(userId, SubscriptionKind.user, 'Subscribing user');
            }),
            mapTo(void 0) // Mappa l'emissione a `void`
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Autorizzazione dell'utente alla modifica dei dati
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public allowMaintenanceFunc(userId: string, apiKey: string): Observable<void> {
        return this.backendService.post(`/v2/users/allow-maintenance`, { userId },{options: { headers: { 'api-key': apiKey } }});
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Metodo chiamato dall utente supporter per revocare l'autorizzazione alla modifica dei dati
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public revokeMaintenanceFunc(userId: string): Observable<void> {
        return this.backendService.post(`/v2/users/revoke-maintenance`, { userId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Controllo se sono autorizzato a modificare i dati dell utente selezionato
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public getMaintenanceAuth(userId: string): Observable<UsertMaintenanceAuthEntity> {
        return this.backendService.get(`/v2/users/maintenance`, { userId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Aggiurno lo user con un nuovo chatId di telegram
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public linkUserWithTelegram(userId: string, chatId: string, apiKey: string): Observable<void> {
        return this.backendService.put(`/v2/users/telegram-link`, 
            { userId, telegramChatId: chatId.toString() }, {options: { headers: { 'api-key': apiKey } }});
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Da vedere bene sotto
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userOutdoorMaintenanceBegin = (
        maintainerName: string,
        userId: string,
        username: string,
        email: string,
        language: Language,
        ttl?: number
    ): Observable<void> =>
        this.backendService.post<void>(`/v2/users/maintenance`, {
            userId,
            maintainerName,
            username,
            email,
            language,
            ttl
        });

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userOutdoorMaintainerGet = (userId: string): Observable<MaintainerByUserEntity> =>
        this.backendService.get<MaintainerByUserEntity>(`/v2/users/maintenance`, {
            userId
        });
}
