<ion-content>
    <item-section-header [title]="'view-chooser.modal.title'" (closeModal$)="closeModal()" [iconClose]="true"></item-section-header>
    <ion-list class="input-padding">
        <searchbar-card></searchbar-card>
        <ion-radio-group [value]="viewSelected" class="ion-no-padding ion-no-margin">
            <p *ngIf="views.length === 0">Nessuna vista disponibile</p>
            <ion-item-sliding *ngFor="let view of views" class="ion-no-padding ion-no-margin">
                <ion-item (click)="radioGroupChange(view)" class="ion-no-padding ion-no-margin">
                    <ion-thumbnail slot="start">
                        <svg-icon
                            [ngClass]="'avatar-' + theme"
                            [src]="'assets/ha/svg/' + view.avatar + '.svg'"
                            [svgStyle]="{ 'height.px': '25px' }"
                            [svgClass]="'avatar-' + theme"
                        ></svg-icon>
                    </ion-thumbnail>
                    <ion-label>
                        <ion-text class="text-input-roboto-regular-16">{{ view.name }}</ion-text>
                    </ion-label>
                    <ion-radio [ngClass]="contentClass" slot="end" [value]="view" mode="ios"></ion-radio>
                </ion-item>
            </ion-item-sliding>
        </ion-radio-group>
    </ion-list>
</ion-content>
