import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserEntity } from 'src/app/models/users.models';
import { UsersService } from 'src/app/services/users.service';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent {
    private subs: Subscription;
    public group: string;
    public image: string;

    public theme: AppTheme;
    public firstName: string;
    public lastName: string;
    public imageDataUrl: string;
    public avatarUser: string;

    @Input() set user(value: UserEntity) {
        if (value) {
            const { userId, theme, firstName, lastName, group, avatar } = value;
            this.theme = theme;
            this.firstName = firstName;
            this.lastName = lastName;

            if (!avatar || this.avatarUser !== avatar) {
                this.avatarUser = avatar;

                if (value.userId) {
                    this.usersService.userDownloadImage(value.userId).subscribe((image) => {
                        if (image) {
                            const blob: Blob = new Blob([new Uint8Array(<Buffer>image['data'])], { type: 'image/webp' });
                            const reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = () => (this.imageDataUrl = <string>reader.result);
                        } else {
                            this.imageDataUrl = 'assets/backgrounds/user-default.webp';
                        }
                    });
                }
            }

            this.group = group;
        }
    }
    @Output()
    userEdit$ = new EventEmitter();

    userEdit(): void {
        this.userEdit$.emit();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private usersService: UsersService) {
        this.subs = new Subscription();
        this.subs.add(
            this.usersService.indoorUserObserver.subscribe((user) => {
                if (!user.avatar || this.avatarUser !== user.avatar) {
                    this.avatarUser = user.avatar ? user.avatar : null;
                    if (user.userId) {
                        this.usersService.userDownloadImage(user.userId).subscribe((image) => {
                            if (image) {
                                const blob: Blob = new Blob([new Uint8Array(<Buffer>image['data'])], { type: 'image/webp' });
                                const reader = new FileReader();
                                reader.readAsDataURL(blob);
                                reader.onload = () => (this.imageDataUrl = <string>reader.result);
                            } else {
                                this.imageDataUrl = 'assets/backgrounds/user-default.webp';
                                this.avatarUser = this.imageDataUrl;
                            }
                        });
                    }
                }
            })
        );
    }
}
