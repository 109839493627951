import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DialogsService } from '../services/dialogs.service';
import { SpinnerService } from '../services/spinner.service';

@Injectable({ providedIn: 'root' })
export class HeaderInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private authService: AuthService, private router: Router, private spinnerService: SpinnerService) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Intercepts 401 response
        https://dev-academy.com/angular-jwt/
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let showSpinner = false; // Flag per indicare se mostrare lo spinner
        const timeoutLoading = setTimeout(() => {
            showSpinner = true;
            this.spinnerService.showSpinner(); // Mostra lo spinner dopo 1500ms
          }, 1500);
        if (!request.url.includes('assets')) {
            //abilitare Intercent se serve vedere le rotte
            // console.log(`Intercepting: ${request.url}`);
        }

        const accessToken = this.authService.getAuthToken();
        if (accessToken) request = this.addAuthToken(request);

        // Gestiamo la richiesta controllando l'errore di ritorno
        return next.handle(request).pipe(
            finalize(async () => {
                clearTimeout(timeoutLoading);
                if (showSpinner) {
                    this.spinnerService.hideSpinner(); // Nasconde lo spinner
                  }
            }),
            catchError((error: any) => {
                //Logghiamo l'errore generico siccome vengono trappati non solo gli errori HttpErrorResponse
                console.error(error);
                //TODO Riabilitare il refresh token
                // if (error.status === 401 && !this.refreshTokenInProgress) {
                //     this.refreshTokenInProgress = true;

                //     return this.authService.accessTokenRefresh().pipe(
                //         switchMap(() => next.handle(this.addAuthToken(request))),
                //         finalize(() => (this.refreshTokenInProgress = false))
                //     );
                // } else if (error.status === 403) this.authService.userNotEnabled();

                //Pezza fino a che non riabilitiamo il refresh token
                if (error.status === 401) {
                    this.router.navigateByUrl('/welcome/login', { replaceUrl: true });
                }

                console.error(`HttpInterceptor - Handling error: ${JSON.stringify(error)}`);
                return throwError(() => new HttpErrorResponse(error));
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    addAuthToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
        const token = this.authService.getAuthToken();

        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }
}
