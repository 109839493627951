<ion-content>
    <item-section-header [title]="'role-chooser.modal.title'" (closeModal$)="closeModal()" [iconClose]="true"></item-section-header>
    <ion-list class="input-padding">
        <ion-radio-group [value]="roleSelected" class="ion-no-padding ion-no-margin">
            <ion-item-sliding *ngFor="let role of roles; trackBy: trackRoles" class="ion-no-padding ion-no-margin">
                <ion-item (click)="radioGroupChange(role)" class="ion-no-padding ion-no-margin">
                    <ion-label>
                        <ion-text class="text-input-roboto-regular-16">{{ role.key | translate }}</ion-text>
                        <br />
                        <ion-text class="label-item-roboto-regular-13 ion-no-padding ion-no-margin" [ngClass]="'medium-'+theme"></ion-text>
                    </ion-label>
                    <ion-radio [ngClass]="contentClass" slot="end" [value]="role"></ion-radio>
                </ion-item>
            </ion-item-sliding>
        </ion-radio-group>
    </ion-list>
</ion-content>
