import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserByUnitEntity, UserEntity } from 'src/app/models/users.models';
import { PlatformService } from 'src/app/services/platform.service';
import { UsersService } from 'src/app/services/users.service';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    templateUrl: './unit-outdoor-users.modal.html',
    styleUrls: ['./unit-outdoor-users.modal.scss']
})
export class UnitOutdoorUsersModal implements OnInit {
    public contentClass: string;
    public className: string;
    public classEmail: string;
    public utenze: any;

    @Input() theme: AppTheme;
    @Input() users: UserByUnitEntity[];

    @Input() firstName: string;
    @Input() lastName: string;
    @Input() email: string;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(
        public modalController: ModalController,
        private translate: TranslateService,
        private alertController: AlertController,
        private platformService: PlatformService,
        private usersService: UsersService
    ) {
        this.setStyle();
        this.platformService.resize.subscribe(async () => this.setStyle());
        this.contentClass = 'ion-padding';
    }
    private setStyle = () => {
        if (this.platformService.isDesktop()) {
            this.className = 'text-input-roboto-regular-16';
            this.classEmail = 'notification-title-regular-13 display-block';
        }
        if (this.platformService.isSmartphone()) {
            (this.className = 'text-input-roboto-regular-16'), (this.classEmail = 'label-items-mobile');
        }
        if (this.platformService.isTablet()) {
            (this.className = 'text-input-tablet'), (this.classEmail = 'label-items-mobile');
        }
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit(): void {
        this.loadImages();
    }

    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    loadImages() {
        const promises = this.users.map((user) =>
            this.imageBuilder(user).then((imageUrl) => ({
                ...user,
                imageUrl
            }))
        );

        Promise.all(promises).then((results) => {
            this.utenze = results;
        });
    }

    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    imageBuilder(user: UserEntity): Promise<string> {
        return new Promise((resolve) => {
            this.usersService.userDownloadImage(user?.userId).subscribe(async (image) => {
                if (image) {
                    const blob: Blob = new Blob([new Uint8Array(<Buffer>image['data'])], { type: 'image/webp' });
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = () => {
                        resolve(reader.result as string);
                    };
                } else {
                    resolve('assets/backgrounds/user-default.webp');
                }
            });
        });
    }

    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public async sendingMail(user: UserByUnitEntity) {
        const alert = await this.alertController.create({
            header: this.translate.instant('units.alert.mail.send.title'),
            message: this.translate.instant('units.alert.mail.send.messagge'),
            buttons: [
                {
                    text: this.translate.instant('button.cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('button.confirm'),
                    handler: () => {
                        this.modalController.dismiss(user);
                    }
                }
            ]
        });
        await alert.present();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public async closeModal(): Promise<void> {
        this.modalController.dismiss();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public modalClose() {
        this.modalController.dismiss();
    }
}
