<ion-card class="card-time-window">
    <ion-grid class="ion-no-margin ion-no-padding">
        <ion-row class="ion-justify-content-between ion-align-items-center">
            <ion-col size="12" class="ion-text-start">
                <svg-icon [src]="'assets/ha/svg/watch.svg'" [svgStyle]="{ 'height.px':25, 'fill':'#c5c5c5'}"></svg-icon>
            </ion-col>
        </ion-row>
        <ion-row class="ion-no-margin ion-no-padding">
            <ion-col size="12">
                <ion-label class="pointer" (click)="addEditDateTime()">
                    <ion-text [color]="theme">{{'user.checkIn' | translate}}</ion-text>
                    {{checkIn | date : 'd/M/yy, h:mm a' }}
                    <ng-container *ngIf="!checkInOnly">
                        <br />
                        <ion-text [color]="theme">{{'user.checkOut' | translate}}</ion-text>
                        {{checkOut | date : 'd/M/yy, h:mm a' }}
                    </ng-container>
                </ion-label>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-card>
